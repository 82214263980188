export default function ScrollToTop(id) {
  const element = document.getElementById(id);
  const offset = 145;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element?.getBoundingClientRect()?.top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  return window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}
