const initstate = {
    messages: [],
    searching: false,
    err: false,
  };
  
  const chatReducer = (state = initstate, action) => {
    switch (action.type) {
      case "MESSAGE_SENT":
        return {
          ...state,
        };
        case "MESSAGE_SENT_ERROR":
        return {
          ...state,
          err: true,
        };
        case "MESSAGE_RESULT":
        return {
          ...state,
          messages:  action.payload
        };
        case "MESSAGE_RESULT_FAILED":
        return {
          ...state,
          err: true,
        };
        case "MESSAGE_DELETED":
        return {
          ...state,
        };
        case "MESSAGE_DLETION_FAILED":
        return {
          ...state,
          err: true,
        };
      default:
        return state;
    }
  };
  
  export default chatReducer;
  