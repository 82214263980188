import React, { lazy, Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import { Switch, Route } from "react-router-dom";
// import { configs } from "./config/configs";
import Loading from "./components/frontend/Loading";
import ScrollToTop from "./components/utils/scrollToTop";

const AdminRoutes = lazy(() => import("./components/dashboard/AdminRoutes"));
const PublicRoutes = lazy(() => import("./components/frontend/PublicRoutes"));
// const { configs } = lazy(() => import("./config/configs"))

function App() {
  const helmetContext = {};
  const hash = window.location.hash.slice(1);

  useEffect(() => {
    setTimeout(() => ScrollToTop(hash), 4000);
  }, [hash]);

  return (
    <Suspense fallback={<Loading />}>
      <HelmetProvider context={helmetContext}>
        <div className="App">
          <Switch>
            <Route path="/dashboard" component={AdminRoutes} />
            <Route path="/" component={PublicRoutes} />
          </Switch>
        </div>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;
