const initState = {
  importList: [],
  loading: false,
  singleProductData: [],
  singleProductDataLoading: false,
  addedImportList: [],
  categoryBeingImported: "",
};

const affiliateReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADED_COMPLETED":
      return {
        ...state,
        importList: [...state.importList, ...action.hits.product],
        loading: false,
        singleProductDataLoading: false,
      };
    case "PRODUCT_REMOVED":
      return {
        ...state,
        importList: [],
      };
    case "LOADING_PRODUCT_STARTED":
      return {
        ...state,
        loading: true,
        singleProductDataLoading: true,
      };
    case "LOADED_WITH_ERROR":
      return {
        ...state,
        loading: false,
        singleProductDataLoading: false,
      };
    case "SINGLE_PRODUCT_LOADED":
      return {
        ...state,
        singleProductData: [...state.singleProductData, action.hits],
        singleProductDataLoading: false,
        loading: false,
      };
    case "PRODUCT_ADDED_TO_IMPORTLIST":
      return {
        ...state,
        addedImportList: [...state.addedImportList, action.productId],
      };
    case "CLEAR_IMPORTLIST":
      return {
        ...state,
        addedImportList: [],
      };
    case "PRODUCT_DELETED_FROM_IMPORTLIST":
      return {
        ...state,
        addedImportList: state.addedImportList.filter(
          (key) => key !== action.productId
        ),
      };
    case "UPDATE_AFFILIATE_CATEGORY":
      return {
        ...state,
        categoryBeingImported: action.categoryId,
      };
    default:
      return state;
  }
};

export default affiliateReducer;
