const initstate = {
  affiliateResult: {},
  results: [],
  searching: false,
  err: "",
  searchKeyword: "",
  searchFilterValues: [],
};

const searchReducer = (state = initstate, action) => {
  switch (action.type) {
    case "SEARCHING_TRUE":
      return {
        ...state,
        searchKeyword: action.keyword,
        searchFilterValues: [],
      };
    case "UPDATE_STORE":
      return {
        ...state,
        results: [...action.payload],
      };
    case "ADDED_SELECTED_VALUE":
      return {
        ...state,
        searchFilterValues: [...state.searchFilterValues, action.payload],
      };
    case "DELETED_SELECTED_VALUE":
      return {
        ...state,
        searchFilterValues: [...action.payload],
      };
    case "FILTER_RESULT":
      return {
        ...state,
        results: [...action.payload],
      };
    case "SORT_BY_A_Z":
      return {
        ...state,
        results: [...action.payload],
      };
    case "SORT_BY_Z_A":
      return {
        ...state,
        results: [...action.payload],
      };
    case "LOW_TO_HIGH_PRICE":
      return {
        ...state,
        results: [...action.payload],
      };
    case "HIGH_TO_LOW_PRICE":
      return {
        ...state,
        results: [...action.payload],
      };
    case "SEARCHING_FALSE":
      if (action.err) {
        return {
          ...state,
          err: "",
        };
      } else {
        return {
          ...state,
          err: "",
          affiliateResult: { ...state.affiliateResult, ...action.hits },
        };
      }
    case "UPDATE_SEARCH_KEYWORD":
      return {
        ...state,
        searchKeyword: action.payload,
      };
    default:
      return state;
  }
};

export default searchReducer;
