const initstate = {
  dealName: [],
  searchKeyword: "",
  ebayDeals: [],
};

const dealsReducer = (state = initstate, action) => {
  switch (action.type) {
    case "LOADED_DEALS_TRUE":
      return Object.assign({}, state, {
        ...state,
        ebayDeals: [...state.ebayDeals, ...action.hits],
      });
    default:
      return state;
  }
};

export default dealsReducer;
